import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { I18nextProvider } from 'react-i18next';
import { client } from './graphql/client';
import { IdentityContextProvider } from 'react-netlify-identity';
import { ThemeProvider } from 'styled-components';

import { theme } from './theme';
import i18n from './i18n';
import { GlobalProvider } from './GlobalState';

//const url = 'http://51.103.18.157';
const url = 'https://edge.weboxit.com/auth';

export const wrapRootElement = ({ element }) => {
  return (
    <IdentityContextProvider url={url}>
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
          <GlobalProvider>
            <ThemeProvider theme={theme}>{element}</ThemeProvider>
          </GlobalProvider>
        </I18nextProvider>
      </ApolloProvider>
    </IdentityContextProvider>
  );
};
