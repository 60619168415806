// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-recovery-js": () => import("../src/pages/account_recovery.js" /* webpackChunkName: "component---src-pages-account-recovery-js" */),
  "component---src-pages-box-tracking-js": () => import("../src/pages/box_tracking.js" /* webpackChunkName: "component---src-pages-box-tracking-js" */),
  "component---src-pages-courier-index-js": () => import("../src/pages/courier/index.js" /* webpackChunkName: "component---src-pages-courier-index-js" */),
  "component---src-pages-courier-pickup-your-box-js": () => import("../src/pages/courier/pickup_your_box.js" /* webpackChunkName: "component---src-pages-courier-pickup-your-box-js" */),
  "component---src-pages-courier-send-a-box-js": () => import("../src/pages/courier/send_a_box.js" /* webpackChunkName: "component---src-pages-courier-send-a-box-js" */),
  "component---src-pages-courier-why-we-boxit-js": () => import("../src/pages/courier/why_we_boxit.js" /* webpackChunkName: "component---src-pages-courier-why-we-boxit-js" */),
  "component---src-pages-customer-support-24-7-live-support-js": () => import("../src/pages/customer_support/24_7_live_support.js" /* webpackChunkName: "component---src-pages-customer-support-24-7-live-support-js" */),
  "component---src-pages-customer-support-contact-us-confirmation-js": () => import("../src/pages/customer_support/contact_us_confirmation.js" /* webpackChunkName: "component---src-pages-customer-support-contact-us-confirmation-js" */),
  "component---src-pages-customer-support-contact-us-js": () => import("../src/pages/customer_support/contact_us.js" /* webpackChunkName: "component---src-pages-customer-support-contact-us-js" */),
  "component---src-pages-customer-support-faq-js": () => import("../src/pages/customer_support/faq.js" /* webpackChunkName: "component---src-pages-customer-support-faq-js" */),
  "component---src-pages-customer-support-index-js": () => import("../src/pages/customer_support/index.js" /* webpackChunkName: "component---src-pages-customer-support-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-info-js": () => import("../src/pages/legal-info.js" /* webpackChunkName: "component---src-pages-legal-info-js" */),
  "component---src-pages-my-boxit-index-js": () => import("../src/pages/my_boxit/index.js" /* webpackChunkName: "component---src-pages-my-boxit-index-js" */),
  "component---src-pages-password-recovery-js": () => import("../src/pages/password_recovery.js" /* webpackChunkName: "component---src-pages-password-recovery-js" */),
  "component---src-pages-pickup-your-box-js": () => import("../src/pages/pickup_your_box.js" /* webpackChunkName: "component---src-pages-pickup-your-box-js" */),
  "component---src-pages-pos-index-js": () => import("../src/pages/pos/index.js" /* webpackChunkName: "component---src-pages-pos-index-js" */),
  "component---src-pages-pos-pickup-your-box-js": () => import("../src/pages/pos/pickup_your_box.js" /* webpackChunkName: "component---src-pages-pos-pickup-your-box-js" */),
  "component---src-pages-pos-send-a-box-js": () => import("../src/pages/pos/send_a_box.js" /* webpackChunkName: "component---src-pages-pos-send-a-box-js" */),
  "component---src-pages-pos-why-we-boxit-js": () => import("../src/pages/pos/why_we_boxit.js" /* webpackChunkName: "component---src-pages-pos-why-we-boxit-js" */),
  "component---src-pages-purchase-failure-js": () => import("../src/pages/purchase_failure.js" /* webpackChunkName: "component---src-pages-purchase-failure-js" */),
  "component---src-pages-purchase-success-js": () => import("../src/pages/purchase_success.js" /* webpackChunkName: "component---src-pages-purchase-success-js" */),
  "component---src-pages-request-invitation-confirmation-js": () => import("../src/pages/request_invitation_confirmation.js" /* webpackChunkName: "component---src-pages-request-invitation-confirmation-js" */),
  "component---src-pages-request-invitation-js": () => import("../src/pages/request_invitation.js" /* webpackChunkName: "component---src-pages-request-invitation-js" */),
  "component---src-pages-send-a-box-js": () => import("../src/pages/send_a_box.js" /* webpackChunkName: "component---src-pages-send-a-box-js" */),
  "component---src-pages-sign-in-js": () => import("../src/pages/sign_in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-confirmation-js": () => import("../src/pages/sign_up_confirmation.js" /* webpackChunkName: "component---src-pages-sign-up-confirmation-js" */),
  "component---src-pages-sign-up-js": () => import("../src/pages/sign_up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-why-weboxit-4-steps-tutorial-js": () => import("../src/pages/why_weboxit/4_steps_tutorial.js" /* webpackChunkName: "component---src-pages-why-weboxit-4-steps-tutorial-js" */),
  "component---src-pages-why-weboxit-for-customers-shared-js": () => import("../src/pages/why_weboxit/for_customers_shared.js" /* webpackChunkName: "component---src-pages-why-weboxit-for-customers-shared-js" */),
  "component---src-pages-why-weboxit-for-customers-js": () => import("../src/pages/why_weboxit/for_customers.js" /* webpackChunkName: "component---src-pages-why-weboxit-for-customers-js" */),
  "component---src-pages-why-weboxit-for-eshops-and-businesses-js": () => import("../src/pages/why_weboxit/for_eshops_and_businesses.js" /* webpackChunkName: "component---src-pages-why-weboxit-for-eshops-and-businesses-js" */),
  "component---src-pages-why-weboxit-index-js": () => import("../src/pages/why_weboxit/index.js" /* webpackChunkName: "component---src-pages-why-weboxit-index-js" */),
  "component---src-pages-why-weboxit-smartest-box-delivery-js": () => import("../src/pages/why_weboxit/smartest_box_delivery.js" /* webpackChunkName: "component---src-pages-why-weboxit-smartest-box-delivery-js" */)
}

