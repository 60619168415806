import React, { createContext, useReducer, useContext } from "react";
import { storeGlobalContext, restoreGlobalContext } from './utils';

const AppReducer = (state, action) => {
    switch (action.type) {
      case "REMOVE_USER":
        storeGlobalContext({
          ...state,
          user: null
        })
        return {
          ...state,
          user: null
        }
      case "SET_USER":
        storeGlobalContext({
          ...state,
          user: action.payload
        })
        return {
          ...state,
          user: action.payload
        }
      default:
        return restoreGlobalContext();
    }
  };

const initialState = restoreGlobalContext()

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  function setUser(user) {
    dispatch({
      type: "SET_USER",
      payload: user
    });
  }

  function removeUser() {
    dispatch({
      type: "REMOVE_USER"
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        user: state.user,
        setUser,
        removeUser
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};


export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  return context;
}
