import dayjs from 'dayjs';
import 'dayjs/locale/el'

export function currency(number) {
  return new Intl.NumberFormat('el-GR', {
    style: 'currency',
    currency: 'EUR',
  }).format(number);
}

export function datetime(ts) {
  return (ts ? dayjs(ts).format("ddd D MM YYYY, h:mm:ss ") : dayjs().format('ddd D MMM YYYY, HH:MM'));
}

export function boxSizeLabel(size) {
  return `No.${parseInt(size)}`;
}

export function phoneNumberMask(str){
  return str.substr(0, 2) + str.substr(2, str.length-5).replace(/\d/g,'*') +str.substr(-3)
}

export const googlePlacesHandler =(googleRes)=>{
  const gAddress = googleRes[0].address_components.reduce((Obj,addr)=>{
    Obj[addr.types[0]] = addr.long_name;
    return Obj
  },{})
  const newStreet = (gAddress.route || 
    gAddress.establishment || 
    gAddress.natural_feature || 
    gAddress.administrative_area_level_5 || 
    gAddress.administrative_area_level_4 || 
    gAddress.administrative_area_level_3 || 
    gAddress.administrative_area_level_2 || 
    gAddress.administrative_area_level_1 ) ? 
    (gAddress.street_number ? 
    `${
      gAddress.route || 
      gAddress.establishment || 
      gAddress.natural_feature || 
      gAddress.administrative_area_level_5 || 
      gAddress.administrative_area_level_4 || 
      gAddress.administrative_area_level_3 || 
      gAddress.administrative_area_level_2 || 
      gAddress.administrative_area_level_1
    } ${gAddress.street_number}` : 
  `${gAddress.route || 
    gAddress.establishment || 
    gAddress.natural_feature || 
    gAddress.administrative_area_level_5 || 
    gAddress.administrative_area_level_4 || 
    gAddress.administrative_area_level_3 || 
    gAddress.administrative_area_level_2 || 
    gAddress.administrative_area_level_1}`) : ""; 

    return {gAddress, streetAddr: newStreet}
}


export const validatePasswordPolicy = (value) =>{
  const checks = {
    length: true,
    containCaps: true,
    containSmall: true,
    containNumber: true,
    containSymbol: true
  };
  let errorMessage = null
  const lengthCount = value.length;
  const capsCount = (value.match(/[A-Z]/g) || []).length
  const smallCount = (value.match(/[a-z]/g) || []).length
  const numberCount = (value.match(/[0-9]/g) || []).length
  const symbolCount = (value.match(/\W/g) || []).length
  
  if(lengthCount < 9){
    checks.length = false;
    errorMessage = 'length'
  }

  if (capsCount < 1) {
    checks.containCaps = false;
    errorMessage = 'containCaps'
  }
  
  if (smallCount < 1) {
    checks.containSmall = false;
    errorMessage = 'containSmall'
  }
  
  if (numberCount < 1) {
    checks.containNumber = false;
    errorMessage = 'containNumber'
  }
  
  if (symbolCount < 1) {
    checks.containSymbol = false;
    errorMessage = 'containSymbol'
  }

  return { 
    checks, 
    errorMessage
  }
}


export function restoreGlobalContext() {
  let globalContext = {user: null};

  try {
    const storedData = localStorage.getItem('globalContext');

    if (storedData) {
      globalContext = JSON.parse(storedData);
    }
  } catch (err) {
    // If stored data is not a strigified JSON this might fail,
    // that's why we catch the error
  }

  return globalContext;
}

export function storeGlobalContext(data) {
  localStorage.setItem('globalContext', JSON.stringify(data));
}
