import { Pagination, Autoplay, EffectFade } from 'swiper/dist/js/swiper.esm';

export const theme = {
  /* mobile > tablet > desktop */
  breakpoints: ['47.9em', '79.9em', '87.49em'],
  // @media screen and (min-width: 40em)
  // @media screen and (min-width: 52em)
  // @media screen and (min-width: 64em)
  // @media screen and (min-width: 80em)

  fontSizes: [10, 12, 14, 16, 18, 19, 23, 29, 34, 54, 84, 107, 187],
  colors: {
    black: '#000000',
    white: '#ffffff',
    drab: '#8c7f41',
    dustyGreen: '#6db56a',
    lightBlueGrey: '#cfd4dc',
    lightGreyBlue: '#aab0ba',
    maize: '#f4d448',
    sunYellow: '#ffd727',
    macaroniAndCheese: '#d9ba34',
    marine: '#032e4f',
    pastelRed: '#dd5353',
    blues: {
      medium: '#2e79bd',
      peacock: '#01549b',
      twilight: '#0b5190',
      veryLight: '#ecedf0',
      veryLightWithOpacity: 'rgba(236, 237, 240, 0.79)',
    },
  },
  space: [0, 2, 4, 8, 16, 32, 64, 128, 256, 512],
  radii: [0, 2, 4, 5, 8, 10, 12, 20],
  fonts: {
    sans: '"PF BeauSans Pro", sans-serif,  -apple-system',
    test: 'cursive',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
};

export const swiperParams = {
  modules: [Pagination, Autoplay, EffectFade],
  spaceBetween: 30,
  // autoplay: true,
  speed: 400,
  loop: true,
  centeredSlides: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  themeColor: '#01549b',
};
